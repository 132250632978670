// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name) => (
    <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
    user: icon('ic_users'),
    food: icon('ic_foods'),
    notification: icon('ic_notifications'),
    ecommerce: icon('ic_ecommerce'),
    analytics: icon('ic_analytics'),
    dashboard: icon('ic_dashboard'),
    file: icon('ic_file'),
    labs: icon('ic_breaker'),
    diagnosis: icon('ic_file-waveform'), 
    medication: icon('ic_medication'),         
    symptoms: icon('ic_symptoms'),    
    comorbidities: icon('ic_diseases'),
    locations: icon('ic_location')    
};

export const Roles = {
    Admin: 'Admin',
    NonAdmin: 'Non Admin'
}

const navConfig = [
    // GENERAL
    // ----------------------------------------------------------------------
    {
        subheader: '',
        items: [
            { title: 'Dashboard', path: PATH_DASHBOARD.dashboard, icon: ICONS.dashboard },
            { title: 'Users', path: PATH_DASHBOARD.users, icon: ICONS.user },
            { title: 'Foods', path: PATH_DASHBOARD.foods, icon: ICONS.food },
            { title: 'Notifications', path: PATH_DASHBOARD.notifications, icon: ICONS.notification },
            { title: 'Non-admin Section', path: PATH_DASHBOARD.section, icon: ICONS.file },
            { title: 'Customers', path: PATH_DASHBOARD.customers, icon: ICONS.file },
            { title: 'Labs', path: PATH_DASHBOARD.labs, icon: ICONS.labs },
            { title: 'AppUsers', path: PATH_DASHBOARD.appusers, icon: ICONS.user },
            { title: 'Floks', path: PATH_DASHBOARD.diagnosis, icon: ICONS.diagnosis },
            { title: 'Symptoms', path: PATH_DASHBOARD.symptoms, icon: ICONS.symptoms },
            { title: 'Diagnoses', path: PATH_DASHBOARD.comorbidities, icon: ICONS.comorbidities },
            { title: 'Locations', path: PATH_DASHBOARD.locations, icon: ICONS.locations },
            { title: 'Medication', path: PATH_DASHBOARD.medication, icon: ICONS.medication }

            // { title: 'Two', path: PATH_DASHBOARD.two, icon: ICONS.ecommerce },
            // { title: 'Three', path: PATH_DASHBOARD.three, icon: ICONS.analytics },
        ],
    },

    // MANAGEMENT
    // ----------------------------------------------------------------------
    //  {
    //      subheader: 'management',
    //      items: [
    //          {
    //              title: 'user',
    //              path: PATH_DASHBOARD.user.root,
    //              icon: ICONS.user,
    //              children: [
    //                  { title: 'Four', path: PATH_DASHBOARD.user.four },
    //                  { title: 'Five', path: PATH_DASHBOARD.user.five },
    //                  { title: 'Six', path: PATH_DASHBOARD.user.six },
    //              ],
    //          },
    //      ],
    //  },
];

export default navConfig;
